import React, { useCallback } from 'react';

import { useFormik } from 'formik';

import type {
  AmountForm,
  CreateTransactionRequestBody,
  TransferData,
} from 'models';
import { useCategories } from 'api/queries';
import { useValidateTransaction } from 'api/queries/queries';
import 'components/forms.css';

import styled from 'styled-components';
import { CurrencyInput } from 'ui/molecules/currency-input';
import { PrevNextStepper } from 'ui/molecules/PrevNextStepper';
import Notifications from 'components/Notifications';
import { useI18nObject } from 'utils/use-i18n-object';
import { formatAmount } from 'utils/format-amount';
import {
  getCurrencyByCountry,
  getCurrencyDecimalScale,
  getExchangeRateRepresentation,
} from 'data/currencies';
import {
  DataLine,
  DataLineHighlight,
  DataLineItem,
} from 'ui/organisms/summary';
import { Dropdown } from 'ui/molecules/dropdown';
import { TextArea } from 'ui/molecules/textarea';
import { StageHeader } from 'ui/atoms/stage-header';

interface Props {
  data: TransferData;
  onSubmit: (v: AmountForm) => void;
  onGoBack: () => void;
  onGoNext: () => void;
  createTransactionBody: CreateTransactionRequestBody;
}

export const SelectAmount = ({
  data: { amount, recipientAccount, senderAccount },
  onSubmit,
  onGoBack,
  onGoNext,
  createTransactionBody,
}: Props) => {
  const LL = useI18nObject();

  const { data: categoryOptions } = useCategories();

  const senderAccountType = senderAccount?.account_type;
  const senderAccountCountry = senderAccount?.country;
  const recipientAccountType = recipientAccount?.account_type;
  const recipientAccountCountry = recipientAccount?.country;

  const currency = getCurrencyByCountry(senderAccountCountry);
  const recipientCurrency = getCurrencyByCountry(recipientAccountCountry);

  const isCrossborderTransfer =
    senderAccountCountry !== recipientAccountCountry;

  const formik = useFormik<AmountForm>({
    initialValues: {
      sendAmount: amount.sendAmount,
      category: amount.category,
      description: amount.description,
    },
    initialErrors: {
      ...(!amount.sendAmount && { sendAmount: '' }),
    },
    validate: ({ sendAmount, category, description }) => {
      const errors: { [key: string]: string } = {};
      if (!sendAmount || sendAmount <= 0) {
        errors.sendAmount = LL.ENTER_AMOUNT();
      }
      if (senderAccountType === 'prefund_account') {
        const fee = validationResult ? parseFloat(validationResult.fee) : 0;
        const totalAmount = sendAmount + fee;
        if (maxLimit && totalAmount > maxLimit) {
          errors.sendAmount = LL.MAX_AMOUNT_PER_TRANSACTION({
            currencyAmount: formatAmount({
              amount: maxLimit,
              currency,
            }),
          });
        }
      }
      if (maxLimit && sendAmount > maxLimit) {
        errors.sendAmount = LL.MAX_AMOUNT_PER_TRANSACTION({
          currencyAmount: formatAmount({
            amount: maxLimit,
            currency,
          }),
        });
      }
      if (minLimit && sendAmount < minLimit) {
        errors.sendAmount = LL.MIN_AMOUNT_PER_TRANSACTION({
          currencyAmount: formatAmount({
            amount: minLimit,
            currency,
          }),
        });
      }
      if (!category) {
        errors.category = LL.CATEGORY_MISSING_ERROR();
      }
      if (!description) {
        errors.description = LL.DESCRIPTION_MISSING_ERROR();
      }
      return errors;
    },
    onSubmit: (values) => {
      onSubmit(values);
      onGoNext();
    },
  });

  const { sendAmount, category, description } = formik.values;
  const onSendAmountChange = useCallback((value: number) => {
    formik.setFieldValue('sendAmount', value);
  }, []);

  const { data: validationResult } = useValidateTransaction({
    ...createTransactionBody,
    send_amount: sendAmount,
  });

  const minLimit = Number(validationResult?.min_limit);
  const maxLimit = Number(validationResult?.max_limit);
  const recipientMinLimit = Number(validationResult?.recipient_min_limit);
  const recipientMaxLimit = Number(validationResult?.recipient_max_limit);

  const restrict = validationResult?.restrict || false;
  const restrictionReason = validationResult?.restriction_reason || '';

  const fee = validationResult?.fee;
  const gh_elevy_amount = validationResult?.gh_elevy_amount;
  const should_charge_gh_elevy = validationResult?.should_charge_gh_elevy;
  const totalAmount = validationResult?.total_amount;
  const exchangeRate = Number(validationResult?.exchange_rate) || 1;
  const recipientAmount = Number(
    (Number(sendAmount) * exchangeRate).toFixed(
      getCurrencyDecimalScale(recipientCurrency),
    ),
  );

  const onCategoryChange = useCallback(
    (value) => {
      const id = Number(value);
      const newCategory = categoryOptions?.find((p) => p.id === id);
      formik.setFieldValue('category', newCategory);
    },
    [categoryOptions, formik.setFieldValue],
  );

  const onSubmitAttempt = () => {
    if (formik.isValid) {
      formik.submitForm();
    } else {
      Notifications.alert(Object.values(formik.errors).join('\n'));
    }
  };

  return (
    <PrevNextStepper
      nextStepActive={formik.isValid}
      onPrevClick={onGoBack}
      onNextClick={onSubmitAttempt}
    >
      {restrict ? (
        <RestrictionWarning>{restrictionReason}</RestrictionWarning>
      ) : (
        <Form onSubmit={onSubmitAttempt}>
          <Section>
            <StageHeader>{LL.PAYMENT_DETAILS()}</StageHeader>
            <CurrencyInput
              title={LL.YOU_SEND()}
              name="amount"
              defaultValue={sendAmount}
              onValueChange={onSendAmountChange}
              currency={currency}
              min={minLimit}
              max={maxLimit}
            />
            <TransactionNumbers>
              {isCrossborderTransfer && (
                <DataLine>
                  <DataLineItem>{LL.CONVERSION_RATE()}</DataLineItem>
                  <DataLineItem>
                    {getExchangeRateRepresentation({
                      currency,
                      recipientCurrency,
                      exchangeRate,
                    })}
                  </DataLineItem>
                </DataLine>
              )}
              <DataLine>
                <DataLineItem>{LL.TRANSACTION_FEES()}</DataLineItem>
                <DataLineItem>
                  {formatAmount({
                    amount: fee,
                    currency,
                  })}
                </DataLineItem>
              </DataLine>
              {should_charge_gh_elevy && (
                <DataLine>
                  <DataLineItem>{LL.TRANSACTION_ELEVY_FEES()}</DataLineItem>
                  <DataLineItem>
                    {formatAmount({
                      amount: gh_elevy_amount,
                      currency,
                    })}
                  </DataLineItem>
                </DataLine>
              )}
              <DataLineHighlight>
                <DataLineItem>{LL.TOTAL_PAID()}</DataLineItem>
                <DataLineItem>
                  {formatAmount({ amount: totalAmount, currency })}
                </DataLineItem>
              </DataLineHighlight>
            </TransactionNumbers>
            <CurrencyInput
              title={LL.RECIPIENT_GETS()}
              name="amount"
              defaultValue={recipientAmount}
              currency={recipientCurrency}
              min={recipientMinLimit}
              max={recipientMaxLimit}
              readOnly
            />
          </Section>
          <Section>
            <Dropdown
              title={LL.CATEGORY()}
              value={category?.id}
              onOptionSelect={onCategoryChange}
              options={categoryOptions}
              emptyValueLabel={LL.SELECT_PURPOSE_OF_TRANSACTION()}
            />
            <TextArea
              onTextChange={(v) => {
                formik.setFieldValue('description', v);
              }}
              title={LL.DESCRIPTION()}
              placeholder={LL.DESCRIPTION()}
              value={description}
              rows={10}
            />
          </Section>
        </Form>
      )}
    </PrevNextStepper>
  );
};

const TransactionNumbers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Form = styled.form`
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

const RestrictionWarning = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem;
`;
