export enum AccountTypes {
  bank_card = 'bank_card',
  bank_account = 'bank_account',
  mobile_money = 'mobile_money',
}

export type Currency = 'GHS' | 'USD' | 'XOF';

export interface Address {}

export interface Country {
  id: number;
  name: string;
  name_fr: string;
  alpha2: string;
  alpha3: string;
}

export interface BusinessProfile {}

export interface Profile {}

export interface User {
  id?: number;
  email: string;
  phone_number?: string;
  onboarding_data: any;
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  recovery_email?: string;
  recovery_phone_number?: string;
  password?: string;
  accepted_terms_and_conditions?: boolean;
  allowed_to_perform_transactions?: boolean;
  allowed_to_perform_transactions_reason?: string;
  allowed_to_create_prefund_balance?: boolean;
  allowed_to_create_bulk_pr?: boolean;
  allowed_to_create_crossborder_transactions?: boolean;
  email_confirmed?: boolean;
  phone_number_confirmed?: boolean;
  profile?: any;
  business_profile?: any;
  security_question?: any;

  id_document?: any;
  has_financial_account?: boolean;
  has_made_transaction?: boolean;

  temp_phone_number?: string;
  temp_email?: string;
  deletion_requested_at?: string;
}

export function isUser(user: User | null | undefined): user is User {
  if (typeof user === 'object' && user !== null) {
    return 'email' in user && 'onboarding_data' in user;
  }
  return false;
}

export interface SecurityQuestion {
  id?: number;
  question: string;
}

export interface SecurityQuestionAnswer {}

export interface TransactionLimit {
  sender_account_type: string | null;
  sender_account_country: string | null;
  recipient_account_type: string | null;
  recipient_account_country: string | null;
  max_sending_amount: number | null;
  min_sending_amount: number | null;
}

export interface LimitsConfig {
  mobile_money_min_limit: number;
  mobile_money_max_limit: number;
  bank_account_min_limit: number;
  bank_account_max_limit: number;
  tg_mobile_money_min_limit: number;
  tg_mobile_money_max_limit: number;
}

export interface Config extends LimitsConfig {
  client_app_dwolla_enabled: boolean;
  client_app_plaid_enabled: boolean;
}

export interface Recipient {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  financial_accounts: FinancialAccount[];
  user: number;
}

export type Operators = 'MTN' | 'AIRTEL' | 'VODAFONE' | 'FLOOZ' | 'TMONEY';

export interface OperatorOption {
  id: Operators;
  name: string;
}

export interface MobileMoney {
  id: number;
  created: string;
  updated: string;
  mobile_number: string;
  operator: Operators;
}

export interface BankAccount {
  id: number;
  account_number: string;
  bank_branch: string | null;
  bank_code: string;
  bank_name: string;
  created: string;
  routing_number: string | null;
  updated: string;
}

export interface BankCard {
  id: number;
  brand: string;
  created: string;
  expiration_month: string;
  expiration_year: string;
  last_four_digits: string;
  type: unknown;
  updated: string;
}

export interface FinancialAccount {
  id: number;
  account_title?: string;
  account_name?: string;
  last_four_digits?: string;
  expiration_date?: string;
  country?: string;
  user?: User;
  recipient?: Recipient;
  account_type: AccountTypeI;
  mobile_money?: MobileMoney;
  bank_account?: BankAccount;
  bank_card?: BankCard;
  verified: boolean | null;
  restrict: boolean | null;
  restriction_reason: string | null;
  is_prefund_account: boolean | null;
}

export interface AmountForm {
  sendAmount: number;
  category: TransactionCategory | null;
  description: string;
}

export interface PaymentRequestForm {
  requestAmount: number;
  category: TransactionCategory | null;
  description: string;
  iCoverFees: boolean;
  dueDate: Date | null;
}

export interface TransferData {
  senderAccount: FinancialAccount | null;
  recipientAccount: FinancialAccount | null;
  amount: AmountForm;
  attachments: string[];
}

export interface PaymentRequestData {
  bulk: boolean;
  requesterAccount: FinancialAccount | null;
  payerIds: number[];
  requestAmounts: { [k: number]: number };
  dueDates: { [k: number]: Date };
  category: TransactionCategory | null;
  description: string;
  iCoverFees: boolean;
}

export type PaymentRequestStatus =
  | 'draft'
  | 'issued'
  | 'paid'
  | 'expired'
  | 'cancelled'
  | 'processing_payment';

export type InvoiceStatus = 'draft' | 'paid' | 'unpaid' | 'pending';

export interface Invoice {
  id: number;
  user: number;
  afi_fee: number;
  amount: string;
  currency: Currency;
  country: string;
  status: InvoiceStatus;
  transaction: number | null;
  payer_account_used: unknown;
  payer: unknown;
  bulk_payment_request: BulkPaymentRequest;
  type: 'bulk_payment_request';
}

export interface PaymentRequest {
  type: 'payment_request';
  id: string;
  requester_name: string;
  payer_name: string | null;
  created: string;
  amount: string;
  currency: Currency;
  description: string;
  due_date: string;
  payer: number;
  payer_covers_fees: boolean;
  requester_account: number;
  status: PaymentRequestStatus;
  logo: string | null;
  title: string;
  bulk_payment_request: number | null;
}

export interface BulkPaymentRequest {
  type: 'bulk_pr';
  id: number;
  created: string;
  total_requested_amount: string;
  currency: Currency;
  description: string;
  payment_requests: string[];
  invoice: Invoice | null;
  status: InvoiceStatus;
}

export interface BulkPaymentRequestDetailed
  extends Omit<BulkPaymentRequest, 'payment_requests'> {
  payment_requests: PaymentRequest[];
}

export interface CreatePaymentRequestBody {
  amount: number;
  description: string;
  due_date: string;
  currency: Currency;
  category: number;
  payer_covers_fees: boolean;
  requester_account: number;
  payer: number;
}

export interface PaymentRequestCreationValidationBody {
  requester_account?: number;
  amount: number;
  currency: string;
  payer?: number;
  category?: number;
  description: string;
  due_date: string;
  payer_covers_fees: boolean;
}

export interface PayPaymentRequestRequestBody {
  country: string;
  customer_name: string;
  account_type: AccountTypeI;
  mobile_money?: {
    operator: Operators | '';
    mobile_numberCountry: string;
    mobile_number: string;
  };
  confirmed_details: boolean;
}

export interface CreateTransactionRequestBody {
  send_amount: number;
  currency: Currency;
  category?: number;
  description: string;
  recipient_account: number;
  sender_account: number;
}

export interface CreateBulkPaymentRequestBody {
  payment_requests: CreatePaymentRequestBody[];
}

export interface InvoicePayRequestBody {
  payer_account: number;
  cvv: string;
}

export interface TransactionCategory {
  id: number;
  name: string;
}

export enum TransactionStatus {
  pending = 'pending',
  succeeded = 'succeeded',
  failed = 'failed',
}

export interface Bank {
  bank_name: string;
  bank_code: string;
}

export interface AttachmentRequirementField {
  name: string;
  optional: boolean;
  stage: 'before' | 'after';
  type: 'image' | 'file';
}

export interface Transaction {
  id?: number;
  status?: TransactionStatus;
  send_amount: string;
  recipient_amount: string;
  recipient_currency: Currency;
  exchange_rate: string;
  total_amount: string;
  currency: Currency;
  fee: string;
  min_limit?: string;
  max_limit?: string;
  recipient_min_limit?: string;
  recipient_max_limit?: string;
  restrict?: boolean;
  restriction_reason?: string;
  sender_account: FinancialAccount;
  recipient_account: FinancialAccount;
  sender: number;
  category: TransactionCategory;
  created: string | null;
  description: string;
  recipient: Recipient;
  invoice: null;
  attachment_requirements: {
    fields: AttachmentRequirementField[];
  };
  is_reversed: boolean;
  gh_elevy_amount: string;
  should_charge_gh_elevy: boolean;
}

export enum OnBoardingState {
  signup = 'signup',
  verifyEmail = 'verifyEmail',
  addPhoneNumber = 'addPhoneNumber',
  verifyPhoneNumberCode = 'verifyPhoneNumberCode',
  addRecoveryOptions = 'addRecoveryOptions',
  verifyIdDocument = 'verifyIdDocument',
  verifyBusiness = 'verifyBusiness',
  verifyIdentity = 'verifyIdentity',
  complete = 'complete',
}

export type DirectionI = 'pull' | 'push';

export type AccountTypeI =
  | 'bank_card'
  | 'bank_account'
  | 'mobile_money'
  | 'prefund_account';

export interface CapabilityPaymentMethodBank {
  bank_code: string;
  bank_name: string;
}

export interface CapabilityPaymentMethod {
  type: AccountTypeI;
  card_brands: string[];
  banks: CapabilityPaymentMethodBank[];
  currency: Currency[];
  processors: string[];
  all_currencies: boolean;
}

export interface Capability {
  payment_flow: DirectionI;
  payment_methods: CapabilityPaymentMethod[];
}

export interface CountryCapability {
  country: string | null;
  country_name: string | null;
  capabilities: Capability[];
}

export type DocumentVerificationStatus = 'pending' | 'declined' | 'approved';

export interface UploadDocumentResponse {
  id: number;
  business_profile: number;
  title: string;
  verification_status: DocumentVerificationStatus;
  document: string;
  created: string;
  updated: string;
}

export interface RecoveryOptionsResponse {
  secret_question: string | null;
}

export interface PlaidTokenResponse {
  success: boolean;
  link_token: {
    authentication_token: string;
  };
}

export interface SmileIdentityTokenResponse {
  success: boolean;
  token: string;
}

export interface UploadDocumentForm {
  title: string;
  document: File;
}

type DocumentType = 'NATIONAL_ID' | 'PASSPORT' | 'DRIVERS_LICENSE';

export interface UploadGhanaDocsForm {
  id_country: string;
  id_front_image: File;
  id_back_image: File;
  id_selfie_image: File;
  id_number: string;
  id_type: DocumentType;
}

export interface UploadAttachmentForm {
  image?: File;
  file?: File;
}

export interface BusinessSettings {
  logo: string | null;
  payment_request_title: string;
}

export interface RecipientsImportForm {
  file: File;
}

export interface UploadNetworkResponse {
  payers: {
    [k: string]: {
      amount: number;
      due_date: string;
    };
  };
  errors: string[];
}

export interface CreatePreFundAccountRequest {
  account_name: string;
  currency: string;
  description: string;
  country: string;
}

export interface PrefundAccount {
  id: number;
  account_name: string;
  balance: string;
  currency: string;
  description: string;
  country: string;
  financial_account: FinancialAccount;
}

export interface PrefundAccountTransactionHistory {
  id: number;
  current_balance: number;
  transaction: Transaction;
}

export interface CreatePaymentRequestValidationResponse {
  amount: string;
  description: string | null;
  created: string | null;
  due_date: string;
  currency: string;
  requester_account: FinancialAccount;
  payer: number;
  category: string | null;
  bulk_payment_request: string | null;
  min_limit: string;
  max_limit: string;
  recipient_min_limit: string;
  recipient_max_limit: string;
  restrict: boolean;
  restriction_reason: string | null;
  errors: string;
}
